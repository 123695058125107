import appStore from "store/index";

import { REQUESTERROR } from "store/actions/type/toaster";

import { lStorage } from "utils/storage";
import { logout } from "utils/common";
import config from "assets/config";

const { xClientIdSRCM } = config;

const interceptor = (ax) => {
  ax.interceptors.request.use(
    (authconfig) => {
      const token = lStorage.get("authInfo")
        ? lStorage.get("authInfo").token
        : null;
      authconfig.headers = {
        Authorization: `Bearer ${token}`,
        "x-client-id": `${xClientIdSRCM}`,
        "Content-Type": "application/json",
      };
      return authconfig;
    },

    (error) => {
      Promise.reject(error);
    }
  );

  ax.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      } else {
        appStore.dispatch({
          type: REQUESTERROR,
          payload: {
            show: true,
            toastMessage: {
              severity: "error",
              summary: "Somthing Went Wrong",
              detail: error.message,
            },
          },
        });
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
