
import { ax } from 'services/base';

const config = { headers: {
  'content-type': 'multipart/form-data'
 } }


export default class PositionService {

  getPosition() { 
    return ax.get("api/v2/me/position-assignments/",config)
  }


}