import {
  EMAILTEMPLATE,
  COMMUNICATIONSTATUS,
  COUNTRY
 
} from 'store/actions/type/dropdown';

const initialState = {
 
  emailTemplate:[],
  communicationStatus: [],
  country: [],
 
};

const dropdownDetails = (state = initialState, action) => {
  switch (action.type) {
   
      case EMAILTEMPLATE:
      return {
        ...state, emailTemplate: action.payload
      };
    
   
    case COMMUNICATIONSTATUS:
      return {
        ...state, communicationStatus: action.payload
      };
      case COUNTRY:
        return {
          ...state, country: action.payload
        };
    default:
      return { ...state };
  }
};

export {
  dropdownDetails
}