import React, { Component } from 'react';

import {
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";

// import { withRouter } from "react-router-dom";

// state 
import { connect } from 'react-redux';

// utils 
import lazy from 'utils/lazy';

// components 
import AuthGuard from 'auth-guard/index';

import Login from 'components/login/Login';


// shared components 
import HFNErrorBoundary from 'shared-components/errorBoundary';

import HFNLoader from 'shared-components/lazyLoading';

import HFNToaster from 'shared-components/toasters';

import HFNConfirmDialog from 'shared-components/confirmDialog';

import TokenExpire from 'shared-components/logout/tokenExpire';



// lazy components 
const LayoutContainer = lazy('layouts/Template');

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <HFNErrorBoundary>
        <HFNLoader>
          <div className="hfn">
            <Switch>

              <Login path="/login"/>
              <AuthGuard path='/' component={LayoutContainer} />

              <Redirect to="login"/>

            </Switch>
          </div>

          <div>
            <HFNToaster />
            <HFNConfirmDialog />
            <TokenExpire />         
          </div>
        </HFNLoader>
      </HFNErrorBoundary>
    );
  }
}

// export default Login
const mapStateToProps = (state) => ({
  ld: state.loginDetails
});

export default withRouter(connect(mapStateToProps)(App));
