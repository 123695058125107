// eslint-disable-next-line no-undef
const configData = process.env;
const config = {};

try {
  config.apiURL =
    configData.REACT_APP_API_BASE_URI || "https://profile.srcm.net/";
  config.xClientIdSRCM =
    configData.REACT_APP_SRCM_XCLIENTID ||
    "wQEYuxIp3nKl6c93vhlLx9sEVFvEuEtKfH3tbA6W";
} catch {
  config.apiURL = "https://profile.srcm.net/";
}

config.maxAllowedFileSize = 20;
config.profileImageMaxFIleSize = 1;

config.cityAutoCompleteURL =
  configData.REACT_APP_AUTOCOMPLETE_API ||
  "https://us-central1-hfn-registration-qa.cloudfunctions.net/";
config.srcmStaticDataURL =
  configData.REACT_APP_STATIC_DATA_SRCM_URL ||
  "https://static-gatsby-qa.web.app/srcmapi/";

export default config;
