import React, { useCallback, useEffect, useRef } from "react";
import { HfnFirebaseAuth } from "@heartfulnessinstitute/react-hfn-profile";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
// utils
import { isLoginAuth } from "utils/common";

import { isEmpty } from "lodash";

import { lStorage } from "utils/storage";
import { LOGIN } from "store/actions/type/login";
import PositionService from "services/position/positionService";
// services
// images
import logo from "assets/images/logo.png";

const Login = (props) => {
  const firebaseRef = useRef(null);
  const hasAPICall = useRef(false);
  const history = useHistory();

  const positionService = new PositionService();

  const getCoordinatorPosition = (srcmToken, myInfo, role) => {
    positionService.getPosition().then((res) => {
      let srcm_group_ids = res.data.results.map((item) => {
        return item.srcm_group.id;
      });

      lStorage.set("authInfo", {
        isUser: true,
        role: "Superadmin",
        token: srcmToken,
        user_type: "U",
        user_role: role,
        user_name: myInfo.name,
        //positionId: res.data.results[0].srcm_group.id,
        positionId: srcm_group_ids,
        coordinatorRole: res?.data?.results?.[0]?.position_type_code,
        // coordinatorRole: "ZC",
        // coordinatorRole: "RC"
      });

      props.dispatch({
        type: LOGIN,
        payload: {
          isUser: true,
          role: "Superadmin",
          token: srcmToken,
          user_type: "U",
          user_role: role,
          user_name: myInfo?.name,
        },
      });
      // history.push("template/listing");
    });
  };

  const processLogin = useCallback(({ myInfo }) => {
    if (myInfo && firebaseRef.current) {
      firebaseRef.current.authFn.onAuthStateChanged((authState) => {
        authState?.getIdToken().then(async (srcmToken) => {
          if (!hasAPICall.current && myInfo.id) {
            hasAPICall.current = true;
            const role = myInfo.user_roles?.includes("communication_admin")
              ? "communication_admin"
              : "";
            if (!isEmpty(srcmToken)) {
              lStorage.set("authInfo", {
                isUser: true,
                role: "Superadmin",
                token: srcmToken,
                user_type: "U",
                user_role: role,
                user_name: myInfo?.name,
              });
              props.dispatch({
                type: LOGIN,
                payload: {
                  isUser: true,
                  role: "Superadmin",
                  token: srcmToken,
                  user_type: "U",
                  user_role: role,
                  user_name: myInfo?.name,
                },
              });
              history.push("template/listing");
            }
            getCoordinatorPosition(srcmToken, myInfo, role);
          }
        });
      });
    }
  }, []);

  const isLogin = () => {
    if (isLoginAuth()) {
      history.push("/");
    } else {
      history.push("/login");
    }
  };

  useEffect(() => {
    isLogin();
  }, []);

  return (
    <div className="scrm_login">
      <img src={logo} className="scrm-login-logo" alt="heartfulness logo" />
      <div>
        <HfnFirebaseAuth
          ref={firebaseRef}
          doLogin={processLogin}
          titleText="Login"
        />
      </div>
    </div>
  );
};

// export default Login
const mapStateToProps = (state) => ({
  ld: state.loginDetails,
});

export default connect(mapStateToProps)(Login);
