const userModuleRoutes = [
  "/",
  "dashboard",
  "template",
  "template/listing",
  "template/single/:id",
  "communication-request",
  "communication-request/listing",
  "communication-request/listing/commlisiting",
  "communication-request/create",
  "report",
  "frequently-asked-questions",
  "help"
];

const userPageAccess = [
  {
    userType: "U",
    route: [
      {
        userRole: "Superadmin",
        excludeRoutes: [],
        includeRoutes: []
      }
        
    ]
  }
];

export {
  userPageAccess,
  userModuleRoutes
};
