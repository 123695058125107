// axios
import axios from 'axios';

// request and response interceptor
import interceptor from 'services/baseInterceptor';

// config
import config from 'assets/config';

export const ax = axios.create({
  baseURL: config.apiURL
});

export const axSRCM = axios.create({
  baseURL: config.srcmURL
});

export const axSRCMGatsby = axios.create({
  baseURL: config.srcmStaticDataURL
});

export const axCityAutoComplete = axios.create({
  baseURL: config.cityAutoCompleteURL
});

interceptor(ax);